import React, { Component } from 'react'
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import Amber_img from '../../../static/images/Amber-White.png';
import people_group from '../../../static/../static/images/people-group.png';
import hrbp_salary from '../../../static/images/hrbp-salary.png';
import money_hr from '../../../static/images/money-hr.png'
import employee_salary from '../../../static/images/employee-salary.png';
import emp_workforce_leave from '../../../static/images/emp-workforce-leave.png';
// components
import RoiResult from './RoiResult';

export default class RoiCalculator extends Component {

    state = {
        step: 1,
        emp_headcount: 5000,
        average_hrbp_salary: 50000,
        hrbp_emp_ratio: 200,
        average_emp_salary: 50000,
        workforce_leave: 50,
        show_result: false,
        roi: 0
    }

    handleOnHeadcountChange = (emp_headcount) => {
        this.setState({
            emp_headcount
        })
    }

    handleOnEmpSalaryChange = (average_emp_salary) => {
        this.setState({
            average_emp_salary
        })
    }

    handleOnWorkforceLeaveChange = (workforce_leave) => {
        this.setState({
            workforce_leave
        })
    }

    handleOnHRBPChange = (average_hrbp_salary) => {
        this.setState({
            average_hrbp_salary
        })
    }

    handleOnHRBPToEmpChange = (hrbp_emp_ratio) => {
        this.setState({
            hrbp_emp_ratio
        })
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    previousStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }

    submitData = () => {
        const { emp_headcount, average_hrbp_salary, hrbp_emp_ratio, average_emp_salary, workforce_leave } = this.state;
        let backfill_cost = (0.15 * (emp_headcount * (workforce_leave / 100)) * average_emp_salary) * 0.6;
        let disengagement_cost = ((0.172 * emp_headcount) * (average_emp_salary * 0.34)) * .04;
        let hrbp_productivity = (0.50 * average_hrbp_salary) * hrbp_emp_ratio;
        let amber_cost = 0;
        if (emp_headcount <= 1000) {
            amber_cost = 70000;
        }
        else if (emp_headcount > 1000 && emp_headcount <= 2500) {
            amber_cost = 100000;
        }
        else if (emp_headcount > 2500 && emp_headcount <= 5000) {
            amber_cost = 150000;
        }
        else {
            amber_cost = 250000;
        }
        let roi = parseFloat((backfill_cost + disengagement_cost + hrbp_productivity) - amber_cost).toFixed(2);
        this.setState({ roi, show_result: true });
    }

    render() {
        const { emp_headcount, average_hrbp_salary, hrbp_emp_ratio, step, average_emp_salary, workforce_leave, roi, show_result } = this.state;
        return (
            <div className="roi-calculator">
                {show_result
                    ? <RoiResult
                        roi={roi}
                        emp_headcount={emp_headcount}
                        average_hrbp_salary={average_hrbp_salary}
                        hrbp_emp_ratio={hrbp_emp_ratio}
                        average_emp_salary={average_emp_salary}
                        workforce_leave={workforce_leave}
                    />
                    : <div className="columns flex-wrap">
                        <div className="column is-12 has-text-centered mg-bottom-30"><br /> <br />
                            <h1 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold">
                                <span>How Much Money Could Amber Save Your Organization?</span>
                            </h1>
                            <h2 className="is-size-5-tablet is-size-6-mobile pd-10">
                                Plug in 5 simple stats and we'll show you how adopting<br /> Amber could boost your ROI.
                            </h2>
                        </div>
                        <div className="column is-12 columns calculator-container">
                            <div className="card card-shadow column is-full-mobile is-10 is-offset-1">
                                <div className="columns">
                                    <div className='column is-8'>
                                        {step === 1 ?
                                            <div className=" column">
                                                <div className="image-heading column">
                                                    <img src={Amber_img} className="radius-top-10 roi-amber-img" alt="Placeholder" />
                                                    <div className="roi-bubble">What is your employee headcount?</div>
                                                </div>
                                                <div className="card-content pd-top-15 ">
                                                    <InputRange
                                                        maxValue={10000}
                                                        minValue={10}
                                                        value={emp_headcount}
                                                        onChange={this.handleOnHeadcountChange} />
                                                </div>
                                            </div> : null}
                                        {step === 2 ?
                                            <div className=" column" >
                                                <div className="image-heading column">
                                                    <img src={Amber_img} className="radius-top-10 roi-amber-img" alt="Placeholder" />
                                                    <div className="roi-bubble">What is your average yearly HR salary?</div>
                                                </div>
                                                <div className="card-content pd-top-15 ">
                                                    <InputRange
                                                        maxValue={100000}
                                                        minValue={10000}
                                                        formatLabel={value => `${value} $`}
                                                        value={average_hrbp_salary}
                                                        onChange={this.handleOnHRBPChange} />
                                                </div>
                                            </div> : null}
                                        {step === 3 ?
                                            <div className=" column" >
                                                <div className="image-heading column">
                                                    <img src={Amber_img} className="radius-top-10 roi-amber-img" alt="Placeholder" />
                                                    <div className="roi-bubble">What is your HRBP headcount?</div>
                                                </div>
                                                <div className="card-content pd-top-15 ">
                                                    <InputRange
                                                        maxValue={500}
                                                        minValue={1}
                                                        formatLabel={value => `${value}`}
                                                        value={hrbp_emp_ratio}
                                                        onChange={this.handleOnHRBPToEmpChange} />
                                                </div>
                                            </div> : null}
                                        {step === 4 ?
                                            <div className=" column" >
                                                <div className="image-heading column">
                                                    <img src={Amber_img} className="radius-top-10 roi-amber-img" alt="Placeholder" />
                                                    <div className="roi-bubble">What is the average yearly employee salary?</div>
                                                </div>
                                                <div className="card-content pd-top-15 ">
                                                    <InputRange
                                                        maxValue={100000}
                                                        minValue={10000}
                                                        formatLabel={value => `${value} $`}
                                                        value={average_emp_salary}
                                                        onChange={this.handleOnEmpSalaryChange} />
                                                </div>
                                            </div> : null}
                                        {step === 5 ?
                                            <div className=" column" >
                                                <div className="image-heading column">
                                                    <img src={Amber_img} className="radius-top-10 roi-amber-img" alt="Placeholder" />
                                                    <div className="roi-bubble">What is your yearly employee turnover percent?</div>
                                                </div>
                                                <div className="card-content pd-top-15 ">
                                                    <InputRange
                                                        maxValue={100}
                                                        minValue={1}
                                                        formatLabel={value => `${value}%`}
                                                        value={workforce_leave}
                                                        onChange={this.handleOnWorkforceLeaveChange} />
                                                </div>
                                            </div> : null}
                                        <div className=" column has-text-centered">
                                            {step > 1 ? <button type="button" onClick={this.previousStep} className="width-100 button mg-right-10 is-outlined is-link">Previous</button> : null}
                                            {step !== 5 ? <button type="button" onClick={this.nextStep} className="width-100 button is-outlined is-link">Next</button> : null}
                                            {step === 5 ? <button type="button" onClick={this.submitData} className="width-100 button submit-color is-success is-outlined">Submit</button> : null}
                                        </div>
                                        <div className=" column is-12">
                                            <span className="is-pulled-left font-16 has-text-weight-semibold">{step}/5</span>
                                        </div>
                                    </div>
                                    <div className="column image-section is-4 has-text-centered">
                                        {step === 1 ?
                                            <img alt="people-group" src={people_group} /> : null}
                                        {step === 2 ?
                                            <img alt="people-group" src={money_hr} width="120px" /> : null}
                                        {step === 3 ?
                                            <img alt="people-group" src={hrbp_salary} width="180px" /> : null}
                                        {step === 4 ?
                                            <img alt="people-group" src={employee_salary} width="180px" /> : null}
                                        {step === 5 ?
                                            <img alt="people-group" src={emp_workforce_leave} width="200px" /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
}